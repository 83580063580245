import axios from "axios";

let customerSvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const customerAxiosInstance = axios.create({
	baseURL: customerSvc,
});

export default {
	customerService: {
		confirmCustomerData(url, customerData, token) {
			return customerAxiosInstance.put(url, customerData, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		getDashboardInfos(customerId, formData, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/dashboard_infos/search" +
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getTransactionVolume(transData, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/customer_transaction_volumes/search" + transData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCardsStatistics(customerData, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/card_statistics/search" + customerData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		searchCards(searchParams, customerId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/search" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		searchOrderCards(searchParams, customerId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/order_beneficiaries/search" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateBeneficiariesByFile(fileData, customerId, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + "/beneficiaries_update",
				fileData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getBeneficiariesByFile(fileData, customerId, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + "/beneficiaries_list",
				fileData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCardsFile(searchParams, customerId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries_file" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCustomerComments(customerId, formData, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/company/" +
				customerId +
				"/product_comments" +
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getMessages(formData, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v2/app_messages" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		markMessageAsRead(message, token) {
			return customerAxiosInstance.put(
				companyRoot + "/v2/app_messages/" + message.id,
				message,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getBeneficiaryDetails(customerId, beneficiaryId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/" +
				beneficiaryId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getBeneficiaryHistory(customerId, beneficiaryId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/" +
				beneficiaryId +
				"/history",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		cardsAction(cardActionOrder, token, user) {
			let config = {};
			if (user !== null) {
				config = {
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
						"X-Up-User": user,
					},
				};
			} else {
				config = {
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				};
			}
			return customerAxiosInstance.post(
				companyRoot +
				"/v1/financiers/" +
				cardActionOrder.customer_id +
				"/cards_action/",
				cardActionOrder,
				config
			);
		},
		modifyBeneficiaryData(customerId, beneficiary, token) {
			return customerAxiosInstance.put(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/" +
				beneficiary.id,
				beneficiary,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getOrderErros(url, token) {
			return customerAxiosInstance.get(url, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},

		modifyAccountData(customerId, beneficiaryId, account, token) {
			return customerAxiosInstance.put(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/" +
				beneficiaryId +
				"/accounts/" +
				account.id,
				account,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteBeneficiary(customerId, beneficiaryId, token) {
			return customerAxiosInstance.delete(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/beneficiaries/" +
				beneficiaryId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		uploadOrderFile(customerId, order, token, user) {
			let config = {};
			if (user !== null) {
				config = {
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
						"X-Up-User": user,
					},
				};
			} else {
				config = {
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				};
			}
			let orderMedium =
				order.type === "EXNT" ? "/paper_orders" : "/card_orders";
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + orderMedium,
				order,
				config
			);
		},
		getOrderTemplateModelFile(customerId, fileData, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + "/order_files",
				fileData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getOrderModelFile(customerId, orderId, orderType, token) {
			let orderMedium =
				orderType === "PAPER" ? "/paper_orders/" : "/card_orders/";
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				orderMedium +
				orderId +
				"/order_files" + (orderType === "PAPER" ? "" : ("/" + localStorage.language)),
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		confirmOrder(link, order, token) {
			return customerAxiosInstance.put(link, order, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		cancelOrder(customerId, order, token) {
			let orderMedium =
				order.type === "PAPER"
					? "/paper_orders/"
					: order.type === "NEW" || order.type === "NEWCR"
						? "/card_orders/"
						: "/cards_action/";

			return customerAxiosInstance.delete(
				companyRoot + "/v1/financiers/" + customerId + orderMedium + order.id,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		urgentOrder(customerId, order, token) {
			return customerAxiosInstance.put(companyRoot + "/v1/financiers/" + customerId + "/cards_action/" + order.id, order, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		searchOrders(searchParams, customerId, token) {
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/orders/search" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getOrderHeader(orderId, customerId, token, orderType) {
			let orderMedium =
				orderType === "PAPER" ? "/paper_orders/" : "/card_orders/";
			return customerAxiosInstance.get(
				companyRoot + "/v1/financiers/" + customerId + orderMedium + orderId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
					},
				}
			);
		},
		getOrderDetails(orderId, customerId, token, orderType, searchParams) {
			let orderMedium =
				orderType === "PAPER" ? "/paper_orders/" : "/card_orders/";
			return customerAxiosInstance.get(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				orderMedium +
				orderId +
				"/cards/search" +
				searchParams,

				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getOrderDocumentFile(link, token, fromService) {
			return customerAxiosInstance.get(
				(fromService ? "" : companyRoot + "/v1/financiers/") + link,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		searchCompany(searchParams, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/financiers/search" + searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sentEmailInvoiceFile(token, formData) {
			return customerAxiosInstance.put(
				companyRoot +
				"/v1/financiers/" +
				formData.code +
				"/customer_order_documents/" +
				formData.invoice,
				{
					email_sender: formData.email_sender,
				},
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAWBForOrder(customerId, orderId, token) {
			return customerAxiosInstance.post(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/paper_orders/" +
				orderId +
				"/awbs",
				{},
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAWBStatus(customerId, orderId, awb, token) {
			return customerAxiosInstance.put(
				companyRoot +
				"/v1/financiers/" +
				customerId +
				"/paper_orders/" +
				orderId +
				"/awbs/" +
				orderId,
				awb,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sendFeedbackMail(formData, token, role) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/user_companies/" + formData.code + "/send_feedback",
				{
					description: formData.description,
				},
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
						"X-Up-CRole": role,
					},
				}
			);
		},
		changeAddressPoint(customerId, formData, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + "/succursal",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		uploadFileForChangeCompanySettings(companyId, formData, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/company/" + companyId + "/company_documents",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getOrderPerson(companyId, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/financiers/" + companyId + "/order_persons",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCustomerIBAN(companyId, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/financiers/" + companyId + "/customer_iban",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateCustomerIBAN(companyId, formData, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + companyId + "/customer_iban",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateCustomerSMSOption(companyId, formData, token) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + companyId + "/customer_sms",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sendEmailToCommecialPerson(companyId, token, productId) {
			return customerAxiosInstance.post(
				companyRoot + "/v1/user_companies/" + companyId + "/send_alert",
				{
					description: productId,
				},
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
						"X-Up-CRole": "CST",
					},
				}
			);
		},
		setPoNumber(customerId, payload, token){
			return customerAxiosInstance.post(
				companyRoot + "/v1/financiers/" + customerId + "/po_number",
				payload,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getPONumber(companyId, productId, token) {
			return customerAxiosInstance.get(
				companyRoot + "/v1/financiers/" + companyId + "/product/" + productId + "/po_number",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
	},
};
