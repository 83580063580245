<template>
  <div class="user">
    <div class="openNotifications" @click="openNotifications('NOTIFICATION')" v-click-outside="hideNotification">
      <div v-if="this.readMessagesCount > 0" class="unread-notifications">
        <span>{{ this.readMessagesCount }}</span>
      </div>
      <img src="@/assets/images/clopotel-notificari.svg" />
      <div class="notification-box" v-show="isOpenNotification">
        <div v-if="this.companyMessages && this.companyMessages.length > 0">
          <NotificationsBox :notifications="this.companyMessages" />
          <a class="notification-view-all cursor-pointer" 
          @click="pushRoute('customer_notifications')"
          >{{
            $t("showAllNotifications")
          }}</a>
        </div>
        <!-- <div v-else-if="this.notificationON === false" class="noNotifications">
            <p>{{ $t("notificationIsOFF") }}</p>
            <p>
              <a :href="'/beneficiary/account?tabSettings=1'">{{ $t("goToSetting") }}</a>
            </p>
          </div> -->
        <div v-else class="noNotifications">
          <p>{{ $t("notificationMessage") }}</p>
        </div>
      </div>
    </div>
    <div>
      <img src="@/assets/images/linie.png" />
    </div>
    <div class="openNotifications cursor-pointer" @click="openNotifications('COMPANY')" v-click-outside="hideCompany">
      <!--  -->
        <font-awesome-icon icon="university" size="2x" />
        <span>{{ " " + selectedCompanyName(selectedCompany) }}</span>
        <img src="@/assets/images/arrow-down.svg" />
      <div class="notification-box" v-show="isOpenCompany">
        <div class="main-notification">
          <table cellpadding="10" cellspacing="0" width="100%" style="text-align: left" align="center"
            class="hover-table">
            <template v-for="companyListItem in this.user.companies" >
            <tr v-if="companyListItem.active" :key="companyListItem.company_id" @click="selectCompany(companyListItem)" 
            class="trhover" :class="selectedCompany.company_id === companyListItem.company_id && 'gri-cell'
              ">
              <td class="notification-footer" v-if="companyListItem.role !== 'FLX'">
                <p>
                  <font-awesome-icon icon="university" />

                  {{ selectedCompanyName(companyListItem) }}
                </p>
              </td>
              <td class="notification-footer" style="text-align: right" v-if="companyListItem.company_id > 0">
                <p>{{ companyListItem.company_id }}</p>
              </td>
            </tr>
          </template>
          </table>
        </div>
      </div>
    </div>
    <div>
      <img src="@/assets/images/linie.png" />
    </div>
    <div class="openUserMenu" v-click-outside="hide" @click="openUserMenu">
      <img src="@/assets/images/settings.png" />
      <!-- <a href="#">{{ this.company.company_id + " " + this.company.name }}</a> -->

      <div class="userMenu" v-show="isOpen">
        <a v-if="this.user.role === 'AFF'"
         href="/affiliate/account"
        class="account cursor-pointer" :class="this.menuSelected.account && 'account-active'">{{ $t("settingsName") }}</a>
        <a v-else
         href="/customer/account"
        class="account cursor-pointer" :class="this.menuSelected.account && 'account-active'">{{ $t("settingsName") }}</a>
         
        <a v-if="this.user.role === 'AFF'" class="faq cursor-pointer" href="/affiliate/faq"
          :class="this.menuSelected.faq && 'faq-active'">{{ $t("faqName") }}</a>
        <a v-else class="faq cursor-pointer"  href="/customer/faq"
          :class="this.menuSelected.faq && 'faq-active'">{{ $t("faqName") }}</a>
        <a v-if="this.company.role === 'FAC'" href="#" class="logout" @click="deselect()">{{ $t("deselectName") }}</a>
        <a v-else href="#" class="logout" @click="logout()">{{
          $t("logoutName")
        }}</a>
      </div>
    </div>
    <LanguageBar @languageChanged="languageChanged" v-if="this.user.role !== 'AFF'" />
  </div>
</template>
<script>
  import Cookies from "js-cookie";
  import httpServiceCustomer from "@/http/http-customer.js";
  import ClickOutside from "vue-click-outside";
  import LanguageBar from "@/components/LanguageBar.vue";
  import NotificationsBox from "@/components/NotificationsBox.vue";

  export default {
    name: "TopBarCompany",
    props: {
      user: Object,
      company: Object,
      companyMessages: Array
    },
    directives: {
      ClickOutside
    },
    components: {
      LanguageBar,
      NotificationsBox
    },
    data() {
      return {
        customerMessages: null,
        menuSelected: {
          faq: false,
          account: false
        },
        userLink: "",
        token: "",
        readMessagesCount: 0,
        isOpenNotification: false,
        isOpenCompany: false,
        isOpen: false,
        // selectedCompany: this.user.companies[0],
        dataLoaded: false,
        config: JSON.parse(localStorage.getItem("jsonFile"))
      };
    },
    methods: {
      openUserMenu() {
        this.isOpen = !this.isOpen;
      },
      openNotifications(windowType) {
        if (windowType === "NOTIFICATION") {
          this.loadMessages();
          this.isOpenNotification = !this.isOpenNotification;
          this.isOpenCompany = false;
        }
        if (windowType === "COMPANY") {
          this.isOpenCompany = !this.isOpenCompany;
          this.isOpenNotification = false;
        }
      },
      isReadNotification(message) {
        message.read = true;
        httpServiceCustomer.customerService
          .markMessageAsRead(message, this.token)
          .catch(error => { });
      },
      loadMessages() {
        let formData =
          "?user_id=" +
          this.company.company_id +
          "&lang=" +
          localStorage.language;
        httpServiceCustomer.customerService
          .getMessages(formData, this.token)
          .then(function (response) {
            // handle success
            return response;
          })
          .then(response => {
            // total no of messages X-Up-Count
            // no of read messages
            this.readMessagesCount = response.headers["x-up-count1"];
            // no of unread messages X-Up-Count2
            this.customerMessages = response.data;
          })
          .catch(error => {
            // handle error
          });
      },
      hide() {
        this.isOpen = false;
      },
      hideNotification() {
        this.isOpenNotification = false;
      },
      hideCompany() {
        this.isOpenCompany = false;
      },
      logout() {
        Cookies.remove("userLink");
        Cookies.remove("token");
        let hasFxlAccess = this.flxUserLevel().hasAccess;
        Cookies.remove("flexbenClient");
        Cookies.remove("flxCookie");
        sessionStorage.removeItem('flx_active_employments');
        sessionStorage.removeItem('flx_active_employment');
        this.$store.state.flx_beneficiaryActiveEmploymentId = null;
        this.$store.state.flx_beneficiaryActiveEmployment = null;
        this.$store.state.flx_activeEmployment = null;
        this.$store.state.flx_beneficiaryActiveEmployments = [];
        if (hasFxlAccess) {
          this.$router.push({
            name: "loginMultibenefits",
            params: { loginUserSelected: false }
          });
        } else {
          this.$router.push({
            name: "login",
            params: { loginUserSelected: false }
          });
        }
        this.$emit("sendUserRole", "", "");
      },
      goBack() {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      },
      deselect() {
        Cookies.set(
          "selectedCompany",
          {
            company_id: 0,
            role: "FAC"
          },
          {
            sameSite: "Strict"
          }
        );
        localStorage.removeItem("customer_data");
        localStorage.removeItem("affiliate_data");
        window.location.href = "/company/search";
      },
      selectCompany(company) {
        Cookies.set(
          "selectedCompany",
          {
            company_id: company.company_id,
            role: company.role
          },
          {
            sameSite: "Strict"
          }
        );
        let activeEmployments = JSON.parse(sessionStorage.getItem('flx_active_employments'));
        let activeCompany = '';

        if (activeEmployments) {
          activeEmployments.forEach((element) => {
            if (element.company_identifier === company.company_id) {
              activeCompany = JSON.parse(JSON.stringify(element));
            }
          });
        }

        if (activeCompany !== '') {
          this.$store.commit('setActiveEmployment', activeCompany);
          sessionStorage.setItem('flx_active_employment', JSON.stringify(activeCompany));
        }

        localStorage.removeItem("affiliate_data");
        localStorage.removeItem("customer_data");
        window.location.href = "/company";
      },
      languageChanged() {
        this.$emit("languageChanged");
      },
      selectedCompanyName(company) {
        if (company.company_id > 0) {
          return company.company_id + " " + company.name;
        } else {
          return this.$t('userTypeLabel_' + company.role);
        }
      }
    },
    computed: {
      selectedCompany() {
        let selectedCompany = this.company;
        if (this.company.company_id > 0){
          return this.company;
        } else {
          selectedCompany = Cookies.getJSON("selectedCompany");
          if (!selectedCompany || selectedCompany.company_id <= 0) {
            return this.user.companies[0];
          } else {
            for (let index in this.user.companies) {
              if (selectedCompany.company_id === this.user.companies[index].company_id) {
                return this.user.companies[index];
              }
            }
          }
        }
        return null;
      }
    },
    created() {
      switch (this.$route.name) {
        case "customer_faq":
          this.menuSelected.faq = true;
          break;
        case "customer_account":
          this.menuSelected.account = true;
          break;
      }


    }
  };
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
